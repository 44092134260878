import { useState } from "react";
import "./SelectList.css";
import Select from "react-select";

const SelectList = ({
  text,
  options,
  handleChange,
  property,
  valueAux = null,
  edicion,
  emptyInputs,
}) => {
  console.log("valueAux 14");
  console.log(valueAux);
  
  return (
    <div className="selectList">
      {text && <label className="title">{text}</label>}
      <Select
        styles={{
          input: (base) => ({
            ...base,
            color: text ? "black" : "white",
            fontWeight: text ? "bold" : "normal",
            borderRadius: "5px",
            margin: "0px",
            paddingRight: "0.4rem",
            paddingLeft: "0.4rem",
          }),
          control: (base) => ({
            ...base,
            backgroundColor: text ? "white" : "rgb(248, 176, 61)", // Aquí se aplica el color de fondo al input
          }),
          singleValue: (base) => ({
            ...base,
            color: text ? "black" : "white", // Color del texto dentro del input
            fontWeight: text ? "bold" : "normal",
          }),
          option: (base) => ({
            ...base,
            fontWeight: text ? "bold" : "normal",
          }),
        }}
        className="inputSelect"
        value={valueAux}
        options={options}
        isDisabled={edicion === "Edicion" ? true : false}
        menuPlacement={text ? "bottom" : "top"}
        onChange={(value) => {
          handleChange({ label: value.label, value: value.value });
        }}
        components={{}}
      />
    </div>
  );
};

export default SelectList;
