//Componentes generales
import React, { useEffect, useState } from "react";
import moment from "moment";
import SelectList from "../SelectList/SelectList";

//Tipos de Estado
import { STATES } from "../../utils/states";

//Tipos de Prioridades
import { PRIORITYA } from "../../utils/priority";

//Tipos de Comprobantes
import { CODFOR } from "../../utils/codigosFormularios";

//Permisos
import { VER_USUARIOS } from "../../utils/permisos";

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

// Estilos
import "./AddFilter.css";

/* 
    Componente de vista dialogo de filtros.
        Donde currentUser es el usuario en sesión.
              usuarios contiene todos los usuarios disponibles.
              cerrar es la función encargada de ocultar el dialogo.
              filtro es la variable del context que define que requerimientos se muestran.
              aplicarFiltros es la función del context que setea el filtro del context.
              workspace es la variable que fltra cuando el usuario se mueve entre workspaces de estados (Ya definidos: Pendientes y Autorizados).
              checkPermisos es la función de la vista que chequea los permisos del usuario en sesión con el enviado por parametro.
*/
function AddFilter({
  usuarios,
  sectoresRequerimientos,
  solicitantes,
  responsables,
  cerrar,
  filtro,
  aplicarFiltros,
  workspace,
}) {
  //States de Filtro Estado.
  const [filterEstado, setFilterEstado] = useState(filtro.filtrosEstado);
  //States de Filtro Rango.
  const [filterRango, setFilterRango] = useState(filtro.filtrosRango);
  //States de Estados Disponibles.
  const [statesAvailable, setStatesAvailable] = useState(STATES);
  //State para manejar el valor seleccionado del select de sectores de requerimientos
  const [selectedReqSector, setSelectedReqSector] = useState({
    label: "",
    value: "",
  });
  //State para manejar el valor seleccionado del select de solicitantes
  const [selectedApplicant, setSelectedApplicant] = useState({
    label: "",
    value: "",
  });
  //State para manejar el valor seleccionado del select de responsables
  const [selectedResponsible, setSelectedResponsible] = useState({
    label: "",
    value: "",
  });

  //States de selección de "Todos" en usuario.
  /* const [allUsers, setAllUsers] = useState((filtro.filtrosEstado.userName.length === usuarios.length)); */

  /*
        Manejador de filtros de estados ante selección. 
        Donde bool es true si fue seleccionado o false si no lo fue. 
        state es el state que fue seleccionado o deseleccionado.
        El parametro bool determina si se agrega al filtro o si se quita al momento de filtrar.
    */
  const handlerClickState = (bool, state) => {
    const temp = filterEstado.state;
    if (bool && !temp.includes(state)) {
      temp.push(state);
      setFilterEstado({ ...filterEstado, state: temp });
    } else {
      const temp2 = temp.filter((f) => f !== state);
      setFilterEstado({ ...filterEstado, state: temp2 });
    }
  };

  /*
        Manejador de filtros de prioridades ante selección.
        Donde bool es true si fue seleccionado o false si no lo fue. 
        state es el state que fue seleccionado o deseleccionado.
        El parametro bool determina si se agrega al filtro o si se quita al momento de filtrar.
    */
  const handlerClickPriority = (bool, prior) => {
    const temp = filterEstado.priority;
    if (bool && !temp.includes(prior)) {
      temp.push(prior);
      setFilterEstado({ ...filterEstado, priority: temp });
    } else {
      const temp2 = temp.filter((f) => f !== prior);
      setFilterEstado({ ...filterEstado, priority: temp2 });
    }
  };

  /*
        Manejador de filtro ante cambio de fecha Desde.
        Donde data es la fecha ingresada por el usuario. 
        El parametro data es el valor con el que se setea el filtro de rango fecha Desde.
    */
  const handlerChangeFecaltDesde = (data) => {
    //const casteo = new Date(data);
    // Dividir la cadena en día, mes y año
    const [day, month, year] = data.split("/");

    // Crear la fecha en formato "AAAA-MM-DD"
    const dateISOFormat = `${year}-${month}-${day}`;

    // Crear el objeto Date con la fecha en formato ISO
    const casteo = new Date(dateISOFormat);

    setFilterRango({
      ...filterRango,
      fecAlt: { ...filterRango.fecAlt, desde: casteo },
    });
  };

  /*const handlerChangeFecaltDesde = (campo , value) => {
        setFilterRango({...filterRango, fecAlt: {...filterRango.fecAlt, campo: value}});
    }*/

  /*
        Manejador de filtro ante cambio de fecha Hasta.
        Donde data es la fecha ingresada por el usuario. 
        El parametro data es el valor con el que se setea el filtro de rango fecha Hasta.
    */
  const handlerChangeFecaltHasta = (data) => {
    //const casteo = new Date(data);
    // Dividir la cadena en día, mes y año
    const [day, month, year] = data.split("/");

    // Crear la fecha en formato "AAAA-MM-DD"
    const dateISOFormat = `${year}-${month}-${day}`;

    // Crear el objeto Date con la fecha en formato ISO
    const casteo = new Date(dateISOFormat);

    setFilterRango({
      ...filterRango,
      fecAlt: { ...filterRango.fecAlt, hasta: casteo },
    });
  };

  /*
        Manejador de filtro ante cambio de fecha Entrega.
        Donde data es la fecha ingresada por el usuario. 
        El parametro data es el valor con el que se setea el filtro de rango fecha Entrega.
    */
  const handlerChangeDeliveryDate = (data) => {
    // Dividir la cadena en día, mes y año
    const [day, month, year] = data.split("/");

    // Crear la fecha en formato "AAAA-MM-DD"
    const dateISOFormat = `${year}-${month}-${day}`;

    // Crear el objeto Date con la fecha en formato ISO
    const casteo = new Date(dateISOFormat);

    setFilterRango({ ...filterRango, deliveryDate: casteo });
  };

  /*
        Manejador de filtro usuarios ante selección.
        Donde bool es true si fue seleccionado o false si no lo fue. 
        state es el state que fue seleccionado o deseleccionado.
        El parametro bool determina si se agrega al filtro o si se quita al momento de filtrar.   
    */
  const handlerClickUsers = (bool, user) => {
    if (bool) {
      setFilterEstado((filterEstadoAnterior) => ({
        ...filterEstadoAnterior,
        userName: [...filterEstado.userName, user],
      }));
    } else {
      setFilterEstado((filterEstadoAnterior) => ({
        ...filterEstadoAnterior,
        userName: filterEstado.userName.filter((i) => i !== user),
      }));
    }
  };

  /*
        Manejador de filtro sectores de requerimientos ante selección.
        Si la opción seleccionada es "-- SELECCIONAR TODOS LOS SECTORES DE REQUERIMIENTOS --",
        va traer el value en "", por lo tanto se va a filtrar por todos los 
        sectores de requerimientos. Sino va a filtrar por el código del sector de requerimientos seleccionado. 
    */

  const handlerClickReqSectors = (value) => {
    setSelectedReqSector(value);

    if (value.value == "") {
      setFilterEstado({
        ...filterEstado,
        tDocAbmReqSector: sectoresRequerimientos.map((sR) => sR.codSem),
      });
    } else {
      setFilterEstado({
        ...filterEstado,
        tDocAbmReqSector: [value.value],
      });
    }
  };

  /*
        Manejador de filtro solicitantes ante selección.
        Si la opción seleccionada es "-- SELECCIONAR TODOS LOS SOLICITANTES --",
        va traer el value en "", por lo tanto se va a filtrar por todos los 
        solicitantes. Sino va a filtrar por el nombre de solicitante seleccionado. 
    */

  const handlerClickApplicants = (value) => {
    setSelectedApplicant(value);

    if (value.value == "") {
      setFilterEstado({
        ...filterEstado,
        tDocAbmApplicant: solicitantes.map((s) => s.idSql),
      });
    } else {
      setFilterEstado({
        ...filterEstado,
        tDocAbmApplicant: [value.value],
      });
    }
  };

  /*
        Manejador de filtro responsables ante selección.
        Si la opción seleccionada es "-- SELECCIONAR TODOS LOS RESPONSABLES --",
        va traer el value en "", por lo tanto se va a filtrar por todos los 
        responsables. Sino va a filtrar por el nombre de responsable seleccionado. 
    */
  const handlerClickResponsibles = (value) => {
    setSelectedResponsible(value);

    if (value.value == "") {
      setFilterEstado({
        ...filterEstado,
        tDocAbmResponsible: responsables.map((s) => s.idSql),
      });
    } else {
      setFilterEstado({
        ...filterEstado,
        tDocAbmResponsible: [value.value],
      });
    }
  };
  /*
        Aplica los filtros seteados llamando a la función del context en views.
        Una vez aplicados, cierra el presente dialogo.
    */
  const handlerAplicaFiltros = async (estado, rango) => {
    if (selectedReqSector.value == "") {
      estado = {
        ...estado,
        tDocAbmReqSector: sectoresRequerimientos.map((sR) => sR.codSem),
      };
    }
    if (selectedApplicant.value == "") {
      estado = {
        ...estado,
        tDocAbmApplicant: solicitantes.map((s) => s.idSql),
      };
    }
    if (selectedResponsible.value == "") {
      estado = {
        ...estado,
        tDocAbmResponsible: responsables.map((s) => s.idSql),
      };
    }
    aplicarFiltros({ filterEstado: estado, filterRango: rango });
    cerrar(null);
  };

  /*
        Manejador de filtro ante selección de "Todos".
        Donde bool es true si fue seleccionado o false si no lo fue. 
        Setea el state de allUser (que determina si se muestra o no "Todos"). 
        Si bool es true setea el filtro con todos los usuarios.
    */
  const handleClickTodos = (bool) => {
    /*      setAllUsers(!allUsers); */
    if (bool) {
      const temp = usuarios.map((u) => u.userName);
      setFilterEstado({ ...filterEstado, userName: temp });
    }
  };

  return (
    <div className="container-gr-filter">
      <div className="label-filter-icon-r">
        <div className="filter-title-r">Filtros</div>
        <div className="filter-icon-r">
          <FontAwesomeIcon icon={faXmark} onClick={() => cerrar(null)} />
        </div>
      </div>
      <div>
        <div className="container-gr-filter-fil">
          <div
            style={{
              display: "flex",
              borderWidth: 1,
              justifyContent: "space-between",
            }}
          >
            <div className="container-gr-subtitles">Comprobantes</div>
          </div>
          <div className="filter-container-states">
            <div className="filter-container-states-gr">
              <div style={{ display: "flex" }}>
                {CODFOR.map(function (comprobante, indexC) {
                  return (
                    <div className="container-states-f" key={indexC}>
                      <input
                        type="checkbox"
                        id={`codFor-${indexC}`}
                        name="comprobantes"
                        value={comprobante.id}
                        disabled
                        defaultChecked={
                          filterEstado.codFor.includes(comprobante.id)
                            ? true
                            : false
                        }
                      />
                      <label
                        htmlFor={`codFor-${indexC}`}
                        className="filter-priority-item-label"
                      >
                        {comprobante.descr}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="container-gr-filter-fil">
          <div
            style={{
              display: "flex",
              borderWidth: 1,
              justifyContent: "space-between",
            }}
          >
            <div className="container-gr-subtitles">Estados</div>
            {workspace.todos === 1 ? (
              <button
                className="button-todos"
                style={{ boxShadow: "4px 5px 5px -3px rgba(0,0,0,0.23)" }}
                onClick={() => {
                  setFilterEstado({
                    ...filterEstado,
                    state:
                      filterEstado.state.length === STATES.length ? [] : STATES,
                  });
                }}
              >
                {filterEstado.state.length === STATES.length
                  ? "Deseleccionar Todos"
                  : "Seleccionar Todos"}
              </button>
            ) : (
              <div></div>
            )}
          </div>
          <div className="filter-container-states">
            <div className="filter-container-states-gr">
              <div style={{ display: "flex" }}>
                {statesAvailable.map(function (st, indexS) {
                  if (workspace.pendientes == 1 || workspace.autorizados == 1) {
                    if (
                      filterEstado.codFor.includes("REQ", "NPI", "NPIP") &&
                      st == "PENDIENTE (REVISIÓN MOSCUZZA)"
                    ) {
                    } else {
                      return (
                        <div className="container-states-f" key={indexS}>
                          <input
                            type="checkbox"
                            id={`state-${indexS}`}
                            name="states"
                            value={st}
                            disabled={
                              workspace.autorizados == 1 ||
                              (workspace.pendientes == 1 &&
                                ((st == "RECHAZADO" || st == "AUTORIZADO") || (filterEstado.codFor.includes("REQ", "NPI", "NPIP") && st== "PENDIENTE")))
                            }
                            defaultChecked={
                              filterEstado.state.includes(st) ? true : false
                            }
                            onClick={(bool) =>
                              handlerClickState(bool.target.checked, st)
                            }
                          />
                          <label
                            htmlFor={`state-${indexS}`}
                            className="filter-priority-item-label"
                          >
                            {st}
                          </label>
                        </div>
                      );
                    }
                  } else {
                    if (
                      filterEstado.codFor.includes("REQ", "NPI", "NPIP") &&
                      st == "PENDIENTE (REVISIÓN MOSCUZZA)"
                    ) {
                    } else {
                      return (
                        <div className="container-states-f" key={indexS}>
                          <input
                            type="checkbox"
                            name={`state-${st}`}
                            value={st}
                            checked={
                              filterEstado.state.includes(st) ? true : false
                            }
                            onChange={(bool) =>
                              handlerClickState(bool.target.checked, st)
                            }
                          />
                          <label className="filter-priority-item-label">
                            {st}
                          </label>
                        </div>
                      );
                    }
                  }
                })}
              </div>
            </div>
          </div>
        </div>
        <div id="priority" className="container-gr-filter-fil">
          <div className="container-gr-subtitles">Prioridad</div>
          <div className="filter-container-priority">
            {PRIORITYA.map(function (item, indexP) {
              return (
                <div className="filter-priority-item" key={indexP}>
                  <input
                    type="checkbox"
                    id={`priority-${indexP}`}
                    name="item"
                    value={item.id}
                    defaultChecked={
                      filterEstado.priority.includes(indexP) ? true : false
                    }
                    onClick={(bool) =>
                      handlerClickPriority(bool.target.checked, item.id)
                    }
                  />
                  <label
                    htmlFor={`priority-${indexP}`}
                    className="filter-priority-item-label"
                  >
                    {item.descr}
                  </label>
                </div>
              );
            })}
          </div>
        </div>

        <div className="container-gr-filter-fil">
          <div className="container-gr-subtitles">Fecha de Movimiento</div>
          <div className="filter-container-date">
            <div className="filter-date-item">
              <div className="filter-date-item-label">Desde</div>
              <input
                type="date"
                className="label-AddFilter-fecha"
                defaultValue={moment(filterRango.fecAlt.desde).format(
                  "YYYY-MM-DD"
                )}
                onChange={(data) => handlerChangeFecaltDesde(data.target.value)}
              />
            </div>
            <div className="filter-date-item">
              <div className="filter-date-item-label">Hasta</div>
              <input
                type="date"
                className="label-AddFilter-fecha"
                defaultValue={moment(filterRango.fecAlt.hasta).format(
                  "YYYY-MM-DD"
                )}
                onChange={(data) => handlerChangeFecaltHasta(data.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="container-gr-filter-fil">
          <div className="container-gr-subtitles">Fecha de Entrega Hasta</div>
          <div className="filter-container-date-h">
            <input
              type="date"
              className="label-AddFilter-fecha"
              defaultValue={moment(filterRango.deliveryDate).format(
                "YYYY-MM-DD"
              )}
              onChange={(data) => handlerChangeDeliveryDate(data.target.value)}
            />
          </div>
        </div>
        {usuarios && usuarios.length > 0 && (
          <div id="users" className="container-gr-filter-fil">
            <div
              style={{
                display: "flex",
                borderWidth: 1,
                justifyContent: "space-between",
              }}
            >
              <div className="container-gr-subtitles">Usuarios</div>
              <button
                className="button-todos"
                style={{ boxShadow: "4px 5px 5px -3px rgba(0,0,0,0.23)" }}
                onClick={() => {
                  setFilterEstado({
                    ...filterEstado,
                    userName:
                      filterEstado.userName.length === usuarios.length
                        ? []
                        : usuarios.map((u) => u.userName),
                  });
                }}
              >
                {filterEstado.userName.length === usuarios.length
                  ? "Deseleccionar Todos"
                  : "Seleccionar Todos"}
              </button>
            </div>
            <div className="filter-container-states">
              <div className="filter-container-states-gr">
                {usuarios.map(function (u, indexU) {
                  return (
                    <div className="container-states-f" key={indexU}>
                      <input
                        type="checkbox"
                        name={`cbox-${u.userName}`}
                        checked={
                          filterEstado.userName.includes(u.userName)
                            ? true
                            : false
                        }
                        onChange={(bool) =>
                          handlerClickUsers(bool.target.checked, u.userName)
                        }
                      />
                      <label className="filter-priority-item-label">
                        {u.userName}
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {sectoresRequerimientos && sectoresRequerimientos.length > 0 && (
          <div id="reqs-sectors" className="container-gr-filter-fil">
            <div
              style={{
                display: "flex",
                borderWidth: 1,
                justifyContent: "space-between",
              }}
            >
              <div className="container-gr-subtitles">
                Sectores de Requerimientos
              </div>
            </div>
            <div className="filter-container-states">
              <div>
                <SelectList
                  options={[
                    {
                      value: "",
                      label:
                        "-- SELECCIONAR TODOS LOS SECTORES DE REQUERIMIENTOS --",
                    },
                    ...sectoresRequerimientos.map((s) => ({
                      value: s.codSem,
                      label: s.codSem + " (" + s.descrp + ")",
                    })),
                  ]}
                  handleChange={handlerClickReqSectors}
                  property="Sector de Requerimientos"
                  valueAux={
                    selectedReqSector.value == ""
                      ? {
                          label:
                            "-- SELECCIONAR TODOS LOS SECTORES DE REQUERIMIENTOS --",
                          value: "",
                        }
                      : selectedReqSector
                  }
                  emptyInputs={[]}
                />
              </div>
            </div>
          </div>
        )}
        {solicitantes && solicitantes.length > 0 && (
          <div id="applicants" className="container-gr-filter-fil">
            <div
              style={{
                display: "flex",
                borderWidth: 1,
                justifyContent: "space-between",
              }}
            >
              <div className="container-gr-subtitles">Solicitantes</div>
            </div>
            <div className="filter-container-states">
              <div>
                <SelectList
                  options={[
                    {
                      value: "",
                      label: "-- SELECCIONAR TODOS LOS SOLICITANTES --",
                    },
                    ...solicitantes.map((s) => ({
                      value: s.idSql,
                      label: s.idSql + " " + s.name,
                    })),
                  ]}
                  handleChange={handlerClickApplicants}
                  property="Solicitante"
                  valueAux={
                    selectedApplicant.value == ""
                      ? {
                          label: "-- SELECCIONAR TODOS LOS SOLICITANTES --",
                          value: "",
                        }
                      : selectedApplicant
                  }
                  emptyInputs={[]}
                />
              </div>
            </div>
          </div>
        )}
        {responsables && responsables.length > 0 && (
          <div id="responsibles" className="container-gr-filter-fil">
            <div
              style={{
                display: "flex",
                borderWidth: 1,
                justifyContent: "space-between",
              }}
            >
              <div className="container-gr-subtitles">Responsables</div>
            </div>
            <div className="filter-container-states">
              <div>
                <SelectList
                  options={[
                    {
                      value: "",
                      label: "-- SELECCIONAR TODOS LOS RESPONSABLES --",
                    },
                    ...responsables.map((r) => ({
                      value: r.idSql,
                      label: r.idSql + " " + r.name,
                    })),
                  ]}
                  handleChange={handlerClickResponsibles}
                  property="Responsable"
                  valueAux={
                    selectedResponsible.value == ""
                      ? {
                          label: "-- SELECCIONAR TODOS LOS RESPONSABLES --",
                          value: "",
                        }
                      : selectedResponsible
                  }
                  emptyInputs={[]}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="container-filter-btn">
        <div
          className="filter-btn"
          onClick={() => handlerAplicaFiltros(filterEstado, filterRango)}
        >
          Aplicar Filtros
        </div>
      </div>
    </div>
  );
}

export default AddFilter;
