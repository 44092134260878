//Componentes generales
import React, { useRef, useState } from "react";

// Iconos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faWarehouse,
    faCaretDown,
    faCaretUp
} from "@fortawesome/free-solid-svg-icons";
import WharehouseSectorList from "../WharehouseSectorList/WharehouseSectorList";


const RenderRow = ({ index, style, warehousesWithSectors = [], filter }) => {

    const [depositoSelected,setDepositoSelected] = useState('');
    const [openDropdown, setOpenDropdown] = useState(false);

    const handleClick = (deposito) => {
        (depositoSelected == deposito) ? setDepositoSelected("") : setDepositoSelected(deposito);
        setOpenDropdown(prevState => !prevState)
    }

    if (filter === '') {
        return (
            <div style={style} className='warehouseRow' id={warehousesWithSectors[index].deposi}>
                <div className='warehouseDataRow'>
                <div className='list-item-icon-w'><FontAwesomeIcon icon={faWarehouse} /></div>
                <div className="label-title-warehouses">Depósito: <h6>{warehousesWithSectors[index].deposi}</h6></div>
                <div className="label-title-warehouses">Descripción: <h6>{warehousesWithSectors[index].descrp}</h6></div>
                <div className="label-title-warehouses">Dirección: <h6>{warehousesWithSectors[index].direcc}</h6></div>
                <div><h5 style={{marginTop:"13px"}}>Sectores: </h5><h6>
                <div className="div-icon-warehouse-sectors" onClick={() => handleClick(warehousesWithSectors[index].deposi)}>
                    {openDropdown === false ? (
                    <FontAwesomeIcon className="icon-warehouse-sectors" icon={faCaretDown} />
                    ):
                    (
                    <FontAwesomeIcon className="icon-warehouse-sectors" icon={faCaretUp} />
                    )}
                    </div></h6></div>
                </div>
                <div style={{width:"100%"}}>
                <WharehouseSectorList sectores={warehousesWithSectors[index].sectors} deposito={warehousesWithSectors[index].deposi} depositoSelected={depositoSelected} />
                </div>
            </div>
        );
    } else {
        const warehouses_filters = warehousesWithSectors.filter(w => w.deposi.toLowerCase().includes(filter.toLowerCase()) || w.descrp.toLowerCase().includes(filter.toLowerCase()) || w.direcc.toLowerCase().includes(filter.toLowerCase()));
        return (
            <div style={style} className='warehouseRow' id={warehouses_filters[index].deposi}>
                <div className='warehouseDataRow'>
                <div className='list-item-icon-p'><FontAwesomeIcon icon={faWarehouse} /></div>
                <span><h5>Depósito: </h5><h6>{warehouses_filters[index].deposi}</h6></span>
                <span><h5>Descripción: </h5><h6>{warehouses_filters[index].descrp}</h6></span>
                <span><h5>Dirección: </h5><h6>{warehouses_filters[index].direcc}</h6></span>
                <span><h5 style={{marginTop:"13px"}}>Sectores: </h5><h6>
                <div className="div-icon-warehouse-sectors" onClick={() => handleClick(warehouses_filters[index].deposi)}><FontAwesomeIcon className="icon-warehouse-sectors" icon={faCaretDown} /></div></h6></span>
                </div>
                <div style={{width:"100%"}}>
                <WharehouseSectorList sectores={warehouses_filters[index].sectors} deposito={warehouses_filters[index].deposi} depositoSelected={depositoSelected} />
                </div>
            </div>
        );
    }
};

export default RenderRow;
