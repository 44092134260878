//Componentes generales
import React, { useReducer } from "react";
import agregarReqReducer from "./agregarReqReducer";
import agregarReqContext from "./agregarReqContext";

//Axios
import clienteAxios from "../../config/axios";

//Rutas de api
import { addFiles, addTransactionDocument, deleteFile, updateTDoc } from "../../api/transactionDocuments";
import { getAbmProduct, getAbmProductByTDoc } from "../../api/abmProduct";
import { updateListProductByTDoc } from "../../api/product";

//Acciones
import { 
    CARGAR_ITEM,
    MODIFICAR_REQ,
    CARGAR_REQ,
    SELECCIONAR_PRODUCTO,
    ELIMINAR_ITEM,
    MODIFICAR_ITEM,
    SELECCIONAR_ITEM,
    CARGAR_PRODUCTOS,
    GUARDAR_REQUERIMIENTO,
    CARGANDO_ADD,
    ERROR_ADD,
    SELECCIONAR_FILE,
    ELIMINAR_FILE,
    ERROR,
    LIMPIAR_ERROR
} from "./agregarReqActions";
import { getNewFormattedDate } from "../../utils/formatDate";

/* 
    State encargado de la llamada al backend para agregar un nuevo requerimiento.
*/
const AgregarReqState = props => {

    //Valores inciales
    const initialState = {
       req: {
            title: "",
            observ: "",
            observAut: "",
            tDocAbmApplicantName: "",
            tDocAbmResponsibleName: "",
            tDocAbmReqSectorCodsem: "",
            tDocAbmWarehouseDeposi: "",
            tDocAbmWarehouseSectorSector: "",
            deliveryDate: new Date(getNewFormattedDate()),
            priority: 0,
            dateOfIssue: new Date(getNewFormattedDate())
       },
       files: [],
       itemsList: [],
       products: [],
       productSelected: null,
       itemSelected: null,
       modReq: false,
       cargando: false,
       error: ""
    };

    const [state, dispatch] = useReducer(agregarReqReducer, initialState);

    /* 
        Función encargada de guardar un requerimiento.
            Donde requeriment es el requerimiento a guardar.
                  items son los items/productos del requerimiento a guardar.
                  user es el usario en sesión.
                  files son los archivos adjuntado a un requerimiento.
    */
    const guardarRequerimiento = async (requeriment, reqSectorForTitle, items, user, files) => {
        try{
            const tDoc = {...requeriment, title:" [" + reqSectorForTitle + "] " + requeriment.title, quantity: items.length, tDocUserID: user._id, userName: user.userName}; 
            const resp = await addTransactionDocument(tDoc, items); //clienteAxios.post(`/transactionDocument`,{transactionDocument: tDoc, products: items});
            
            if(files.length > 0) await agregarArchivos(resp.data.data, files);

            dispatch({
                type: GUARDAR_REQUERIMIENTO
            });
            console.log("Guardar requermiento")
            console.log(resp.data.data);
            return resp.data.data;

        }catch(error){
            dispatch({
                type: ERROR,
                payload: error.msg
            })
        }
    }

    /* 
        Función que actualiza la lista de productos.
    */
    const actualizarProducts = async () => {

        try{

            const resp = await getAbmProduct();

            dispatch({
                type: CARGAR_PRODUCTOS,
                payload: resp.data.data
            })

        }catch(error){
            console.log(error);
        }
    }

    /*
        Función encargada de cargar el requerimiento.
            Donde req es el requerimiento a cargar.
        Actualmente no esta en uso.
    */
    const cargarRequerimiento = (req) => {
        dispatch({
            type: CARGAR_REQ,
            payload: req
        })
    }

    /* 
        Función que selecciona un producto.
            Donde product es el producto.
    */
    const seleccionarProducto = (product) => {
        dispatch({
            type: SELECCIONAR_PRODUCTO,
            payload: product
        })
    }

    /* 
        Función que carga un item.
            Donde item es el item a cargar.
    */
    const cargarItem = (item) => {
        dispatch({
            type: CARGAR_ITEM,
            payload: item 
        })
    }

    /* 
        Función elimina un item.
            Donde index es la posición del item a eliminar.
    */
    const eliminarItem = (index) => {
        dispatch({
            type: ELIMINAR_ITEM,
            payload: index
        })
    }

    /* 
        Función que modifica un item.
            Donde index es la posición del item a modificar.
                  nuevoItem es el item modificado.
    */
    const modificarItem = (index, nuevoItem) => {
        dispatch({
            type: MODIFICAR_ITEM,
            payload: {
                index,
                item: nuevoItem
            }
        })
    }

    /* 
        Función que selecciona el item.
            Donde item es el item a seleccionar.
    */
    const seleccionarItem = (item) => {
        dispatch({
            type: SELECCIONAR_ITEM,
            payload: item
        })
    }

    /* 
        Función que modifica un requerimiento.
            Donde modificar es el requerimiento modificado.
    */
    const modificarRequerimiento = (modificar) => {
        dispatch({
            type: MODIFICAR_REQ,
            payload: modificar
        })
    }

    /* 
        Función que edita un requerimiento.
            Donde tDoc es el requerimiento modificado.
                  productList son los items del requerimiento modificado.
                  files son los archivos adjuntados al requerimiento modificado.
                  filesBase son los archivos que tenia antes de la modificación.
                  usuario es el usuario en sesión.
    */
    const editarRequerimientoBE = async (tDoc, reqSectorForTitle, productList, files, filesBase, usuario) => {
        try {
            //clienteAxios.put('/transactionDocument', { id: tDoc._id, updates: tDoc, fecMod: tDoc.fecMod, user:usuario });
            updateTDoc(tDoc, {...tDoc, title:"[" + reqSectorForTitle + "] " + tDoc.title, quantity: productList.length}, usuario);
            //clienteAxios.put('/product/updateListByTDoc', { tDoc, productList });
            updateListProductByTDoc(tDoc, productList);

            //Actualizar los archivos
            const comparacion =  compararListaFiles(files, filesBase);
            
            //En base al resultado agrego
            if(comparacion.agregar.length > 0) await agregarArchivos(tDoc, comparacion.agregar);
            if(comparacion.eliminar.length > 0) await eliminarArchivos(comparacion.eliminar);

            dispatch({
                type: GUARDAR_REQUERIMIENTO
            });

            return true;

        } catch (error) {

            dispatch({
                type: ERROR_ADD,
                payload: error.message
            });
            return false;
        }
    }

    /* 
        Función para carga.
        No esta en uso.
    */
    const cargando = async  () => {
        dispatch({
            type: CARGANDO_ADD
        })
    }
    
    /* 
        Función que selecciona un archivo.
    */
    const seleccionarFile = async () => {

        try {
            const fileUpload = null

            if(fileUpload.name !== undefined){
                dispatch({
                    type: SELECCIONAR_FILE,
                    payload: fileUpload
                });
            }

        } catch (error) {
        }
    }

    /* 
        Función que eliminar un archivo.
            Donde fle es el archivo a eliminar.
    */
    const eliminarFile = (file) => {
        console.log(file);
        dispatch({
            type: ELIMINAR_FILE,
            payload: file
        });
    }

    /* 
        Función que limpia el valor de error.
    */
    const limpiarError = async () => {
        dispatch({
            type: LIMPIAR_ERROR,
            
        });
    }


    return(
        <agregarReqContext.Provider
            value={{
                req: state.req,
                modReq: state.modReq,
                itemsList: state.itemsList,
                products: state.products,
                productSelected: state.productSelected,
                itemSelected: state.itemSelected,
                isCargando: state.cargando,
                error: state.error,
                cargarRequerimiento,
                modificarRequerimiento,
                seleccionarProducto,
                cargarItem,
                eliminarItem,
                modificarItem,
                seleccionarItem,
                actualizarProducts,
                guardarRequerimiento,
                cargando,
                seleccionarFile,
                eliminarFile,
                limpiarError,
                editarRequerimientoBE

            }}
        >

            {props.children}

        </agregarReqContext.Provider>
    )
    
}

export default AgregarReqState;

/* 
    Actualmente no esta en uso.
*/
const toFileUpload = (file) => {

    let { name, mimeType, size, uri } = file;

    let fileToUpload = {
        name: name,
        size: size,
        uri: uri,
        type: mimeType
    };

    return fileToUpload;

}

/* 
    Agrega archivos a un requerimiento.
        Donde req es el requerimiento.
              files son los archivos.
*/
const agregarArchivos = async (req, files) => {
    try {
        const formData = new FormData();

        files.map((f, i) => {
            formData.append(`${i}`, f);
        });
        
        formData.append('prefijo', `${req.tDocCodFor + "-" + req.tDocNroFor}_`);
        
        console.log("VOY A REALIZAR LA PETICION");
        await addFiles(formData);//clienteAxios.post('/transactionDocument/file', formData, {headers: {'Content-Type': 'multipart/form-data'}, timeout: 0});
        console.log("REALICE LA PETICION");
        
        return true;

    } catch (error) {
        console.log("Error en agregar archivos");
        console.log(error);
        throw new Error(`Error al guardar archivos del requerimiento ${req.tDocCodFor + "-" + req.tDocNroFor}`)
    }
}

/* 
    Función que compara los archivos que se estan viendo contra los archivos de la base de datos y decide que hacer.
        Donde listaState son los archivos actuales.
              listaBD son los archivos de la base de datos.
*/
const compararListaFiles = (listaState, listaBD) => {
    
    const result = {
        "agregar": [],
        "eliminar": []
    }

    if(listaBD.length === 0 && listaState.length > 0) result.agregar = listaState;
    else if(listaState.length === 0 && listaBD.length > 0) result.eliminar = listaBD;

    else if(listaState.length !== 0 && listaBD.length !== 0){
        listaBD.map((fbd) => {if(listaState.findIndex((fs) => fs.name === fbd.name) === -1) result.eliminar.push(fbd); });
        listaState.map((fs) => {if(listaBD.findIndex((fbd) => fbd.name === fs.name) === -1) result.agregar.push(fs); });
    }

    return result;
}

/* 
    Función que elimina archivos.
        Donde listaFiles son los archivos a eliminar.
*/
const eliminarArchivos = async (listaFiles) => {
    try{
        for (const file of listaFiles) await deleteFile(file.name); //clienteAxios.delete(`/transactionDocument/file/${file.name}`);

    }catch(error){
        console.log(error);
        throw new Error(`Error al eliminar archivos`)
    }
}